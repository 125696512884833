<template>
  <div class="flex_box" :style="checkBackgroundImg()">
    <div class="login_pos">
      <div class="login_box">
        <Form ref="loginForm" :model="loginForm" :rules="ruleLogin">
          <div class="login_title">User Login</div>
          <FormItem prop="username">
            <Input
              type="text"
              v-model="loginForm.username"
              placeholder="User Name"
              size="large"
            >
              <Icon type="ios-person-outline" slot="prepend"></Icon>
            </Input>
          </FormItem>
          <FormItem prop="password">
            <Input
              type="password"
              v-model="loginForm.password"
              placeholder="Password"
              size="large"
            >
              <Icon type="ios-lock-outline" slot="prepend"></Icon>
            </Input>
          </FormItem>
          <FormItem>
            <Checkbox v-model="loginForm.rememberAccount">{{
              $t("remberPassword")
            }}</Checkbox>
          </FormItem>
          <FormItem>
            <Button
              :loading="loading"
              long
              type="primary"
              size="large"
              @click="handleSubmit"
            >
              {{ $t("login") }}
            </Button>
          </FormItem>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showAddEdit: false,
      loading: false,
      loginForm: {
        username: "",
        password: "",
        rememberAccount: true,
      },
      ruleLogin: {
        username: [
          {
            required: true,
            message: "Please fill in the username name",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Please fill in the password.",
            trigger: "blur",
          },
          {
            type: "string",
            min: 6,
            message: "The password length cannot be less than 6 bits",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    init() {
      let query = this.$route.query;
      let rememberAccount = localStorage.getItem("rememberAccount");
      if (rememberAccount == "true") {
        this.loginForm.username = localStorage.getItem("username") || "";
        this.loginForm.password = localStorage.getItem("password") || "";
        this.loginForm.rememberAccount =
          Boolean(localStorage.getItem("rememberAccount")) || false;
        this.$set(this, "loginForm", this.loginForm);
      }
      if (JSON.stringify(query) == "{}") {
        let path = this.$router.history.current.path;
        let newQuery = JSON.parse(JSON.stringify(query));
        newQuery.theme = "light";
        newQuery.language = "en";
        this.$router.push({ path, query: newQuery });
      }
    },
    checkBackgroundImg() {
      let str = require("/public/img/login_bj.jpg");
      return { background: `url(${str})`, "background-size": "100% 100%" };
    },
    handleSubmit() {
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          const loginForm = this.loginForm,
            username = loginForm.username,
            password = loginForm.password;
          if (username == "admin" && password == "123456") {
            this.$router.push({
              path: "dashboard",
              query: this.$route.query,
            });
          } else {
            this.loading = false;
            this.$Message.error(
              "Account or password is wrong. Please modify it and try again"
            );
          }
        }
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less">
.flex_box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
  width: 100%;
  // background: url("../../../static/img/login_bg.jpg");
  background: #0f0f29;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  background-position: center;
  // background: linear-gradient(to right, #36d1dc, #5b86e5);
  justify-content: center;
  z-index: 10;
  .ivu-input {
    background: transparent !important;
    color: #fff !important;
    border: 1px solid #75779a !important;
  }
}
.text_right {
  text-align: right;
}
.login_box {
  background-clip: padding-box;
  width: 450px;
  padding: 40px 25px 5px;
  transition: 1s;
  background: rgba(33, 41, 77, 0.8);
  box-shadow: 2px 2px 7px 1px #081b45;
}
.login_logo span {
  font-size: 36px;
}
.login_title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  color: #fff;
  margin-bottom: 30px;
}
.remember_div {
  height: 20px;
  text-align: left;
  color: #fff;
}
.login_button {
  float: right;
  padding: 5px 55px 6px !important;
  border-radius: 0 !important;
  transition: 0.5s;
  height: 40px;
}
.login_button:hover {
  color: #105eb7 !important;
  border-color: #105eb7 !important;
  transition: 0.5s;
  background: rgba(255, 255, 255, 0.8);
}
@media screen and (max-width: 640px) {
  .flex_box {
    background-size: auto 100%;
    justify-content: center;
  }
  .login_box {
    width: 90%;
  }
}
.login_box .ivu-input::placeholder {
  color: #666 !important;
}
.login_box .ivu-icon {
  color: #2196f3 !important;
  line-height: 26px !important;
}
.login_box .ivu-form-item-error-tip {
  color: #f90;
  color: #105eb7;
}
.login_pos {
  position: relative;
  z-index: 2;
}
</style>
